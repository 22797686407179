import { render, staticRenderFns } from "./ModalCupom.vue?vue&type=template&id=3b8332c7&scoped=true"
import script from "./ModalCupom.vue?vue&type=script&lang=js"
export * from "./ModalCupom.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8332c7",
  null
  
)

export default component.exports