<template>
  <div class="minha-conta" :style="items.length == 0 ? 'height:100vh' : ''">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div>
      <div class="bg-gradient">
        <div class="container text-center pt-3">
          <h3>Cupom Seguro</h3>
          <div class="h-line" style="width: 142px"></div>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <ValidationObserver v-slot="{ invalid }">
        <form data-test-id="form-novo-cliente">
          <div
            style="
              display: flex;
              gap: 10px;
              align-items: baseline;
              font-size: 18px;
            "
          >
            <div>Periodo:</div>
            <div>de</div>
            <div>
              <div class="form-group mb-3">
                <ValidationProvider
                  rules="required"
                  name="inicio"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="tel"
                    class="form-control"
                    :class="classes"
                    v-model="dataInicio"
                    v-mask="'##/##/####'"
                    placeholder="início"
                    autocomplete="nope"
                  />
                  <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div>até</div>
            <div>
              <div class="form-group mb-3">
                <ValidationProvider
                  rules="required"
                  name="fim"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="tel"
                    class="form-control"
                    :class="classes"
                    v-model="dataFim"
                    v-mask="'##/##/####'"
                    placeholder="fim"
                    autocomplete="nope"
                  />
                  <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4 mb-2">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    Cupom
                  </span>
                </div>
                <input type="text" class="form-control" v-model="nome" />
              </div>
            </div>
            <div class="col-md-4 mb-2">
              <b-button
                class="btn"
                variant="success"
                data-test-id="btn-continuar"
                @click.prevent="obterCupons()"
                :disabled="invalid"
              >
                <i class="fas fa-search"></i> Buscar
              </b-button>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4 mb-2">
              <b-button
                class="btn"
                variant="success"
                data-test-id="btn-continuar"
                @click.prevent="abrirModal()"
              >
                <i class="fas fa-plus"></i> Cadastrar
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div v-if="vendasCheckout.length > 0">
        <!-- Nossa Table -->
        <div style="padding-bottom: 20px">
          <div class="overflow-auto box-one">
            <b-table
              id="my-table"
              hover
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              small
            >
              <template #cell(id)="data">
                <b-button
                  @click.prevent="abrirModal(data.value)"
                  variant="success"
                  >...</b-button
                >
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              pills
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <div v-else>Por favor inicie um filtro na pesquisa</div>
    </div>
    <Loading :criandoSeguro="true" v-if="buscarDados" />
    <ModalCupom :cupom="cupom" :showModal="showModal" @hideCupom="hideModal" />
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import ModalCupom from "../../components/Admin/ModalCupom";
import moment from "moment";
import Loading from "../../components/Usuario/Loading.vue";
import formatacaoMoeda2 from "../../helpers/formatacaoMoeda2";
export default {
  components: {
    Loading,
    ModalCupom,
    SideNav,
  },
  data() {
    return {
      formatacaoMoeda2,
      id: undefined,
      cupom: {},
      showModal: false,
      config,
      buscarDados: false,
      dataFim: undefined,
      dataInicio: undefined,
      perPage: 10,
      nome: "",
      vendasCheckout: [],
      fields: [
        {
          key: "codigo",
          label: "Nome",
          sortable: true,
        },
        {
          key: "percentual",
          label: "Percentual",
          sortable: true,
        },
        {
          key: "dataInicio",
          label: "Validade Inicio",
          formatter: (value) => {
            if (value) {
              value = moment(value).format("DD/MM/YYYY");
            }
            return value;
          },
        },
        {
          key: "dataFim",
          label: "Expiração",
          formatter: (value) => {
            if (value) {
              value = moment(value).format("DD/MM/YYYY");
            }
            return value;
          },
        },
        {
          key: "quantidade",
          label: "Quantidade",
        },
        {
          key: "id",
          label: "",
        },
      ],
      currentPage: 1,
      items: [],
      checkouts: {},
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.dataInicio = moment().startOf("month").format("DD/MM/YYYY");
    this.dataFim = moment().add(1, "days").format("DD/MM/YYYY");
  },
  methods: {
    abrirModal(value) {
      if (value) {
        this.id = value;
        this.cupom = this.items.find((w) => w.id == value);
      } else {
        this.cupom = {};
      }
      this.showModal = true;
    },
    hideModal(values) {
      if (values?.modificou) {
        this.dataInicio = moment().startOf("month").format("DD/MM/YYYY");
        this.dataFim = moment().add(1, "days").format("DD/MM/YYYY");
        this.obterCupons();
      }
      this.showModal = false;
    },
    async obterCupons() {
      try {
        this.buscarDados = true;
        let datainicio = this.dataInicio
          ? moment(this.dataInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;
        let datafim = this.dataFim
          ? moment(this.dataFim, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;

        const data = {
          codigo: this.nome,
          dataInicio: datainicio,
          dataFim: datafim,
        };
        var response = await axios.post(
          `${config.API_URLV2}/cupom/buscarListaCupom`,
          data
        );
        // Calcula a diferença em milissegundos

        this.items = response.data;
        this.vendasCheckout = response.data;
        this.buscarDados = false;
      } catch (error) {
        this.buscarDados = false;
        Swal.fire({
          title: "Não foi possível buscar os planos de seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
  },
};
</script>
<style>
.table th {
  vertical-align: top;
  border-top: 1px solid #ffffff;
}
.table-sm th,
.table-sm td {
  padding: 1rem;
}
</style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.box-one {
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
  transition: box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
}
.notas {
  font-size: 16px;
}
.minha-conta {
  font-family: "Montserrat";
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}
.pagination {
  justify-content: center;
}
.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
hr {
  margin-top: 10px;
  margin-bottom: 15px;
}

.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
