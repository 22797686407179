<template>
  <b-modal
    :visible="showModal"
    title="Cadastrar histórico de feedback"
    :hide-footer="true"
    @hide="hideModal"
    size="lg"
  >
    <div>
      <div>
        <ValidationObserver v-slot="{ invalid }">
          <form class="mt-2" >
            <div class="row">
              <div class="col-4 form-group">
                <ValidationProvider
                  name="nome"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <label>Cupom</label>
                  <input
                    v-model="cupom.codigo"
                    :disabled="cupom.id"
                    type="text"
                    style="text-decoration: uppercase"
                    class="form-control"
                    :class="classes"
                  />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-4 form-group">
                <ValidationProvider
                  name="quantidade"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <label>Quantidade</label>
                  <input
                    v-model="cupom.quantidade"
                    type="tel"
                    max="10000"
                    min="1"
                    v-mask="'#####'"
                    class="form-control"
                    :class="classes"
                  />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-4 form-group">
                <ValidationProvider
                  name="quantidade"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <label>Percentual (%)</label>
                  <input
                    v-model="cupom.percentual"
                    type="tel"
                    max="100"
                    min="1"
                    :disabled="cupom.id"
                    v-mask="'###'"
                    class="form-control"
                    :class="classes"
                  />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-6 form-group">
                <ValidationProvider
                  rules="required"
                  name="inicio"
                  v-slot="{ errors, classes }"
                  ><label>Data Inicio da vigência</label>
                  <input
                    type="tel"
                    class="form-control"
                    :class="classes"
                    v-model="dataInicio"
                    v-mask="'##/##/####'"
                    placeholder="início"
                    autocomplete="nope"
                  />
                  <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6 form-group">
                <ValidationProvider
                  rules="required"
                  name="fim"
                  v-slot="{ errors, classes }"
                  ><label>Data de expiração</label>
                  <input
                    type="tel"
                    class="form-control"
                    :class="classes"
                    v-model="dataFim"
                    v-mask="'##/##/####'"
                    placeholder="fim"
                    autocomplete="nope"
                  />
                  <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <button
                  type="submit"
                  @click="salvar()"
                  class="btn btn-success btn-block"
                  :disabled="invalid || loadingText"
                >
                  <span v-if="!loadingText && cupom.id">Atualizar cupom</span>
                  <span v-else-if="!loadingText && !cupom.id"
                    >Cadastrar cupom</span
                  >
                  <span v-else>Por favor aguarde...</span>
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-danger btn-block"
                  @click.prevent="hideModal(false)"
                  :disabled="loadingText"
                >
                  <span>Cancelar</span>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import formatacaoData from "../../helpers/formatacaoData";
import moment from "moment";
export default {
  props: ["showModal", "cupom"],
  data() {
    return {
      historico: [],
      config,
      formatacaoData,
      loading: false,
      dataFim: undefined,
      dataInicio: undefined,
      loadingText: false,
    };
  },
  watch: {
    "cupom.codigo": function (value) {
      this.cupom.codigo = value.toUpperCase().trim();
    },
    showModal: function (newValue) {
      if (newValue) {
        this.dataInicio = undefined;
      this.dataFim = undefined;
        if (this.cupom && this.cupom.dataInicio) {
          const dataInicio = moment(this.cupom.dataInicio).format("DD/MM/YYYY");
          this.dataInicio = dataInicio;
        }
        if (this.cupom && this.cupom.dataFim) {
          const dataFim = moment(this.cupom.dataFim).format("DD/MM/YYYY");
          this.dataFim = dataFim;
        }
      }
    },
  },
  methods: {
    hideModal(value) {
      this.$emit("hideCupom", {
        modificou: value != true ? false : value,
      });
    },
    async salvar() {
      this.loadingText = true;
      try {
        let datainicio = this.dataInicio
          ? moment(this.dataInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;
        let datafim = this.dataFim
          ? moment(this.dataFim, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;

        if (!datafim || !datainicio || datainicio > datafim) {
          Swal.fire({
            icon: "info",
            title: "Oops...",
            text: "Por verifique a Data Inicio da vigência e a Data de expiração! Precisam ser um periodo valido!",
          });
          this.loadingText = false;
          return;
        }
        const data = {
          id: this.cupom.id,
          codigo: this.cupom.codigo,
          dataInicio: datainicio,
          dataFim: datafim,
          quantidade: this.cupom.quantidade,
          percentual: this.cupom.percentual,
        };
        let mensagem = "";
        if (!data.id) {
          await axios.post(`${config.API_URLV2}/cupom`, data);
          mensagem = "O cupom foi cadastrado com sucesso!!";
        } else {
          await axios.put(`${config.API_URLV2}/cupom`, data);
          mensagem = "O cupom foi atualizado com sucesso!!";
        }
        Swal.fire("Cupom", mensagem, "success");
        this.hideModal(true);
        this.loadingText = false;
      } catch (error) {
        this.loadingText = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
           text: error?.response?.data?.message
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
